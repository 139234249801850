import { LoyaltyEarningRule, Status } from '@wix/ambassador-loyalty-v1-loyalty-earning-rule/types';
import { LoyaltyProgram, Type as BackendChannelType } from '@wix/ambassador-loyalty-v1-program/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

import { ChannelType, SimpleRule } from '../../types/domain';
import { createRuleDescription } from '../create-rule-description';

interface ToSimpleRulesParams {
  rules: LoyaltyEarningRule[];
  flowAPI: ControllerFlowAPI;
  loyaltyProgram: LoyaltyProgram;
}

export const toSimpleRules = ({ rules, flowAPI, loyaltyProgram }: ToSimpleRulesParams): SimpleRule[] => {
  const rulesArray = rules.reduce((prev, rule) => {
    const results: SimpleRule[] = [];

    if (rule.status !== Status.ACTIVE) {
      return prev;
    }
    if ('conversionRate' in rule) {
      rule.conversionRate!.configs?.forEach((config, index) => {
        const result: SimpleRule = {
          title: rule.title ?? '',
          description: createRuleDescription({ rule, flowAPI, loyaltyProgram, configIndex: index }),
          tierId: config.tierId,
        };
        results.push(result);
      });
    } else if ('fixedAmount' in rule) {
      rule.fixedAmount!.configs?.forEach((config, index) => {
        const result: SimpleRule = {
          title: rule.title ?? '',
          description: createRuleDescription({ rule, flowAPI, loyaltyProgram, configIndex: index }),
          tierId: config.tierId,
        };

        if (rule.triggerActivityType === 'wix_loyalty-social_media_channel_followed') {
          const channels = loyaltyProgram.socialMediaSettings?.channels
            ?.filter((channel) => channel.enabled && channel.type !== BackendChannelType.UNKNOWN_CHANNEL)
            ?.map((channel) => ({
              type: channel.type as ChannelType,
              url: channel.url!,
            }));

          result.socialMedia = channels ? { channels } : undefined;
        }
        results.push(result);
      });
    }

    return [...prev, ...results];
  }, [] as SimpleRule[]);
  return rulesArray;
};
